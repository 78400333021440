<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-center">
          <div class="flex justify-center whitespace-nowrap">
            <h1 class="text-3xl">Maintenance Event Log</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div class="grid grid-cols-1 mb-8">
        <div class="m-auto"  v-if="errorFetching">
          <ErrorCard v-if="errorFetching" message="Admin Access Detail Not Available"/>
        </div>
        <div v-else>
          <div class=" w-full">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                :totalRows="maintenanceLogs.totalElements"
                :isLoading.sync="isLoading"
                max-height="750px"
                theme="polar-bear"
                :sort-options="{
                enabled: false,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: this.serverParams.size,
                  perPageDropdownEnabled: false,
                  dropdownAllowAll: false,
                  setCurrentPage: maintenanceLogs.number + 1,
                }"
                :rows="maintenanceLogs.content"
                :columns="columns"
                styleClass="vgt-table bordered"
            >
              <template slot="table-row" slot-scope="props">
                <span :class="[props.column.field === 'createDateTime' && 'whitespace-nowrap', 'text-sm']">
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
              <template slot="table-column" slot-scope="props">
                 <span :class="[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange  block p-0']">
                {{props.column.label}}
                 </span>
              </template>
              <div slot="emptystate">
                No Maintenance Logs
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import { VueGoodTable } from 'vue-good-table';
import {mapActions, mapState} from "vuex";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";

export default {
  name: "MaintenanceLogs",
  mixins: [LocalLogFormatters],
  components: {PageTemplate, ErrorCard, BasicNav, VueGoodTable},
  data(){
    return {
      errorFetching: false,
      isLoading: false,
      columns: [
        {
          label: 'Event',
          field: 'inMaintenance',
          formatFn: this.formatMaintenanceEvent,
          type: String,
        },
        {
          label: 'User',
          field: 'username',
          formatFn: this.formatLogUser,
          type: String,
        },
        {
          label: 'Date/Time',
          field: 'createDateTime',
          formatFn: this.formatLogDate,
          type: String,
        },
        {
          label: "Cause",
          field: "cause",
          type: String,
        }
      ],
      rows: [],
      serverParams: {
        page: 0,
        size: 8
      }
    }
  },
  computed: {
    ...mapState("GeneralSettings", ["maintenanceLogs"]),
  },
  methods: {
    ...mapActions('GeneralSettings', ['getMaintenanceLogs']),
    updateParams(newProps) {
      this.serverParams = {...this.serverParams, ...newProps};
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage -1});
      this.loadItems();
    },
    loadItems(){
      this.isLoading = true;
      try{
        this.getMaintenanceLogs(this.serverParams);
      }catch(e){
        this.errorFetching = true;
      }
      this.isLoading = false;
    }
  },
  mounted() {
    this.loadItems();
  }
}
</script>
<style>
.chevron.right::after {
  border-left-color: rgba(245, 120, 18, 1) !important;
}
.chevron.left::after {
  border-right-color: rgba(245, 120, 18, 1) !important;
}
</style>